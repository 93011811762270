<template>
	<div class="AuthorToolbar">
		<div class="Heading Heading--h5 Heading--reverse u-margin-none">{{ comName }}</div>
		<CloDropdown v-if="versionList.length" reverse>
			<template #heading>
				Version: {{ current }}
			</template>
			<template #panel>
				<ul class="UnorderedList UnorderedList--flat">
					<li v-for="(version, index) in versionList" :key="index">
						<a  :href="version.url">
							{{ formatStatus(version.status) }} - {{ version.ver }}
						</a>
					</li>
				</ul>
			</template>
		</CloDropdown>
		<div v-else class="u-text-3">Version: {{ current }}</div>
		<div>
		<CloDropdown reverse right v-if="allowCitationControl === 'true' && citationStyles.length">
			<template #heading>
				Citations: {{ citationStyles.find(x => x.key === citationType).label }}
			</template>
			<template #panel>
				<ul class="UnorderedList UnorderedList--flat">
					<li v-for="(citationStyle, index) of citationStyles" :key="index">
						<a :href="citationStyle.url">
							{{ citationStyle.label }}
						</a>
					</li>
				</ul>
			</template>
		</CloDropdown>
		</div>
	</div>
</template>

<script>
import CloUnorderedList from '@/base/CloUnorderedList/CloUnorderedList';
import CloDropdown from '@/base/CloDropdown/CloDropdown';

export default {
	name: 'AuthorToolbar',
	components: {
		CloUnorderedList,
		CloDropdown
	},
	data() {
		return {
			citationStyles: [
				{ key: 'numeric', label: 'Numeric', url: this.citationNumericUrl },
				{ key: 'author', label: 'Author', url: this.citationAuthorUrl }
			]
		}
	},
	props: {
		comName: {
			type: String,
			default: ''
		},
		allowCitationControl: {
			type: String,
			default: null
		},
		citationNumericUrl: {
			type: String,
			default: ''
		},
		citationAuthorUrl: {
			type: String,
			default: ''
		},
		citationType: {
			type: String,
			default: 'numeric'
		},
		current: {
			type: String,
			default: ''
		},
		versions: {
			type: String,
			default: '[]'
		},
	},
	computed: {
		versionList() {
			return JSON.parse(this.versions);
		}
	},
	methods: {
		formatStatus(status) {
			if (status) {
				return status.charAt(0).toUpperCase() + status.slice(1);
			}
		}
	}
}
</script>

<style lang="scss">
.AuthorToolbar {
	@include colorReverse;
	background-color: $color-black;
	@include inset-squish-md;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>