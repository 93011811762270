<template>
  <div class="HybridMedia">
    <CloLoadingAnimation v-if="loading" class="LoadingAnimation--basic" />
    <CloResultsGallery
      v-if="list.length"
      :list="list"
      integratedLightbox
      :speciesList="false"
      caption="notSpecies"
      class="u-stack-sm"
    />
    <ul class="InlineList InlineList--divided u-text-3">
      <li>
        <a :href="'https://ebird.org/map/' + speciesCode">
          <CloIcon symbol="map" size="sm" />
          eBird map
        </a>
      </li>
      <li>
        <a
          :href="
            mlSearchApiBaseUrl +
            '/catalog?taxonCode=' +
            speciesCode +
            '&mediaType=p&sort=rating_rank_desc'
          "
        >
          <CloIcon symbol="photo" size="sm" />
          <span v-if="counts.photo !== null"
            >&nbsp;{{ counts.photo }}&nbsp;</span
          >Photos
        </a>
      </li>
      <li>
        <a
          :href="
            mlSearchApiBaseUrl +
            '/catalog?taxonCode=' +
            speciesCode +
            '&mediaType=a&sort=rating_rank_desc'
          "
        >
          <CloIcon symbol="audio" size="sm" />
          <span v-if="counts.audio !== null"
            >&nbsp;{{ counts.audio }}&nbsp;</span
          >Recordings
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import CloResultsGallery from "@/media/CloResultsGallery/CloResultsGallery.vue";
import CloLoadingAnimation from "@/base/CloLoadingAnimation/CloLoadingAnimation.vue";
import CloIcon from "@/icons/CloIcon/CloIcon.vue";

export default {
  name: "HybridMedia",
  components: {
    CloResultsGallery,
    CloLoadingAnimation,
    CloIcon,
  },
  props: {
    speciesCode: {
      type: String,
      required: true,
    },
    mlSearchApiBaseUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      list: [],
      loading: true,
      counts: {
        photo: null,
        audio: null,
        video: null,
      },
    };
  },
  mounted() {
    this.getMedia();
    this.getCounts();
  },
  methods: {
    getMedia() {
      axios
        .get(this.mlSearchApiBaseUrl + "/api/v1/search", {
          params: {
            taxonCode: this.speciesCode,
            sort: "rating_rank_desc",
            count: 4,
            mediaType: "p",
          },
        })
        .then((res) => {
          this.loading = false;
          this.list = res.data.results.content;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCounts() {
      axios
        .get("/bow/api/v1/media/counts/" + this.speciesCode)
        .then((res) => {
          this.counts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.HybridMedia {
  max-width: 50rem;
  @include stack-lg;
}
</style>
