<template>
	<span v-html="text"></span>
</template>

<script>


export default ({
	name: 'DefinitionHtml',
	components: {
	},
	props: {
		text: {
			type: String,
			required: true,
		},
	},
});
</script>

<style lang="scss">

</style>
