<template>
	<CloMedia class="Media--contained Media--dropshadow">
		<template v-slot:content>
			<img
				class="loaded"
				:src="mlDownloadBase + item.mainIllustrationAssetId + '/160'"
				:alt="item.commonName + ' - ' + item.mainIllustrationCredit"
			/>
		</template>
		<template v-slot:caption>
			<div class="Heading Heading--h5 u-margin-none">
				<span class="Heading-main">{{ item.commonName }}</span>
				<span class="Heading-sub Heading-sub--sci">{{ item.scientificName }}</span>
			</div>
		</template>
	</CloMedia>
</template>

<script>
import CloHeading from '@/base/CloHeading/CloHeading.vue'
import CloMedia from '@/media/CloMedia/CloMedia.vue'
export default {
	components: {
		CloHeading,
		CloMedia,
	},
	props: {
		mlDownloadBase: {
			type: String,
			required: true,
		},
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.Media {
	img {
		max-height: 6rem;
		max-width: 8rem;
		@include breakpoint(xlarge) {
			max-height: 8rem;
			max-width: 10rem;
		}
		transform: scaleX(-1);
		transition: opacity 0.3s ease;
		opacity: 0;
		&.loaded {
			opacity: 1;
		}
	}
}
</style>