import Vue from 'vue';

// main.js includes global styles, polyfills, shared scripts, i18n, and component initialization
//import '@/base/global-js/main.js';

// include reset and utility classes
import '@/base/global-css/_classes.scss';

// js utilities that need direct access to the DOM
import '@/base/global-js/GoToTop.js';
import '@/base/global-js/LazyLoader.js';
import '@/base/global-js/LinkMimic';
import '@/base/global-js/ResizeListener';
import '@/base/global-js/Tooltip';
//import "@/base/global-js/TogglePanel";
import '@/base/global-js/Utilities';

import '@/base/global-js/LazyImage.js';
import '@/base/global-js/LazyTrigger.js';

// Component dependencies

// base
import CloBadge from '@/base/CloBadge/CloBadge';
Vue.component('CloBadge', CloBadge);

import CloButton from '@/base/CloButton/CloButton';
Vue.component('CloButton', CloButton);

import CloDialog from '@/base/CloDialog/CloDialog';
Vue.component('CloDialog', CloDialog);

import CloDivider from '@/base/CloDivider/CloDivider';
Vue.component('CloDivider', CloDivider);

import CloHeading from '@/base/CloHeading/CloHeading';
Vue.component('CloHeading', CloHeading);

import CloInlineList from '@/base/CloInlineList/CloInlineList';
Vue.component('CloInlineList', CloInlineList);

import CloPage from '@/base/CloPage/CloPage';
Vue.component('CloPage', CloPage);

import CloSkipTo from '@/base/CloSkipTo/CloSkipTo';
Vue.component('CloSkipTo', CloSkipTo);

import CloSpecies from '@/base/CloSpecies/CloSpecies';
Vue.component('CloSpecies', CloSpecies);

import CloTable from '@/base/CloTable/CloTable';
Vue.component('CloTable', CloTable);

import CloUnorderedList from '@/base/CloUnorderedList/CloUnorderedList';
Vue.component('CloUnorderedList', CloUnorderedList);

// forms
import CloInputText from '@/forms/CloInputText/CloInputText';
Vue.component('CloInputText', CloInputText);

import CloInputTextarea from '@/forms/CloInputTextarea/CloInputTextarea';
Vue.component('CloInputTextarea', CloInputTextarea);

import CloSuggest from '@/forms/CloSuggest/CloSuggest';
Vue.component('CloSuggest', CloSuggest);

// grid
import CloGridAuto from '@/grid/CloGridAuto/CloGridAuto';
Vue.component('CloGridAuto', CloGridAuto);

import CloGridFlex from '@/grid/CloGridFlex/CloGridFlex';
Vue.component('CloGridFlex', CloGridFlex);

// icons
import CloIcon from '@/icons/CloIcon/CloIcon';
Vue.component('CloIcon', CloIcon);

// layouts
import CloBlurb from '@/layouts/CloBlurb/CloBlurb';
Vue.component('CloBlurb', CloBlurb);

import CloLogosGrid from '@/layouts/CloLogosGrid/CloLogosGrid';
Vue.component('CloLogosGrid', CloLogosGrid);

import CloHero from '@/layouts/CloHero/CloHero';
Vue.component('CloHero', CloHero);

import CloMessage from '@/layouts/CloMessage/CloMessage';
Vue.component('CloMessage', CloMessage);

import CloSectionHeading from '@/layouts/CloSectionHeading/CloSectionHeading';
Vue.component('CloSectionHeading', CloSectionHeading);

import CloSectionMenu from '@/layouts/CloSectionMenu/CloSectionMenu';
Vue.component('CloSectionMenu', CloSectionMenu);

import CloTaxonomyTree from '@/layouts/CloTaxonomyTree/CloTaxonomyTree';
Vue.component('CloTaxonomyTree', CloTaxonomyTree);

// logos
import CloLogoAos from '@/logos/CloLogoAos/CloLogoAos';
Vue.component('CloLogoAos', CloLogoAos);

import CloLogoBnaLockup from '@/logos/CloLogoBnaLockup/CloLogoBnaLockup';
Vue.component('CloLogoBnaLockup', CloLogoBnaLockup);

import CloLogoBowLockup from '@/logos/CloLogoBowLockup/CloLogoBowLockup';
Vue.component('CloLogoBowLockup', CloLogoBowLockup);

import CloLogoEbird from '@/logos/CloLogoEbird/CloLogoEbird';
Vue.component('CloLogoEbird', CloLogoEbird);

import CloLogoLab from '@/logos/CloLogoLab/CloLogoLab';
Vue.component('CloLogoLab', CloLogoLab);

import CloLogoLabSapsucker from '@/logos/CloLogoLabSapsucker/CloLogoLabSapsucker';
Vue.component('CloLogoLabSapsucker', CloLogoLabSapsucker);

import CloLogoMacaulayLockup from '@/logos/CloLogoMacaulayLockup/CloLogoMacaulayLockup';
Vue.component('CloLogoMacaulayLockup', CloLogoMacaulayLockup);

import CloLogoNbLockup from '@/logos/CloLogoNbLockup/CloLogoNbLockup';
Vue.component('CloLogoNbLockup', CloLogoNbLockup);

import CloLogoMerlin from '@/logos/CloLogoMerlin/CloLogoMerlin';
Vue.component('CloLogoMerlin', CloLogoMerlin);

// media
import CloCarouselResponsive from '@/media/CloCarouselResponsive/CloCarouselResponsive';
Vue.component('CloCarouselResponsive', CloCarouselResponsive);

import CloGridCarousel from '@/media/CloGridCarousel/CloGridCarousel';
Vue.component('CloGridCarousel', CloGridCarousel);

import CloImageAspectRatio from '@/media/CloImageAspectRatio/CloImageAspectRatio';
Vue.component('CloImageAspectRatio', CloImageAspectRatio);

import CloInlineMedia from '@/media/CloInlineMedia/CloInlineMedia';
Vue.component('CloInlineMedia', CloInlineMedia);

import CloLightbox from '@/media/CloLightbox/CloLightbox';
Vue.component('CloLightbox', CloLightbox);

import CloMedia from '@/media/CloMedia/CloMedia';
Vue.component('CloMedia', CloMedia);

import CloMediaCredit from '@/media/CloMediaCredit/CloMediaCredit';
Vue.component('CloMediaCredit', CloMediaCredit);

import CloResultsGallery from '@/media/CloResultsGallery/CloResultsGallery';
Vue.component('CloResultsGallery', CloResultsGallery);

import CloSpectrogramPlayer from '@/media/CloSpectrogramPlayer/CloSpectrogramPlayer';
Vue.component('CloSpectrogramPlayer', CloSpectrogramPlayer);

import CloVideoPlayer from '@/media/CloVideoPlayer/CloVideoPlayer';
Vue.component('CloVideoPlayer', CloVideoPlayer);

// navigation
import CloFooter from '@/navigation/CloFooter/CloFooter';
Vue.component('CloFooter', CloFooter);

import CloHeader from '@/navigation/CloHeader/CloHeader';
Vue.component('CloHeader', CloHeader);

import CloToolbar from '@/navigation/CloToolbar/CloToolbar';
Vue.component('CloToolbar', CloToolbar);

// visualizations
import CloConservationStatus from '@/visualizations/CloConservationStatus/CloConservationStatus';
Vue.component('CloConservationStatus', CloConservationStatus);

import CloStat from '@/visualizations/CloStat/CloStat';
Vue.component('CloStat', CloStat);

import CloStatList from '@/visualizations/CloStatList/CloStatList';
Vue.component('CloStatList', CloStatList);

import CloVisProduct from '@/visualizations/CloVisProduct/CloVisProduct';
Vue.component('CloVisProduct', CloVisProduct);

// deprecated
import CloHeaderBeta from '@/deprecated/CloHeaderBeta';
Vue.component('CloHeaderBeta', CloHeaderBeta);

import CloPanel from '@/deprecated/CloPanel';
Vue.component('CloPanel', CloPanel);

// wordpress
import CloWordpress from '@/wordpress/CloWordpress/CloWordpress';
Vue.component('CloWordpress', CloWordpress);

// local components and pages
import GlobalUtilities from './GlobalUtilities';
Vue.component('GlobalUtilities', GlobalUtilities);

import LatestUpdates from './LatestUpdates';
Vue.component('LatestUpdates', LatestUpdates);

import MyActivityBadges from './MyActivityBadges';
Vue.component('MyActivityBadges', MyActivityBadges);

import DefinitionHtml from './DefinitionHtml';
Vue.component('DefinitionHtml', DefinitionHtml);

import AuthorToolbar from './AuthorToolbar';
Vue.component('AuthorToolbar', AuthorToolbar);

import SalesFunnel from './SalesFunnel';
Vue.component('SalesFunnel', SalesFunnel);

import SubscribeAd from './SubscribeAd';
Vue.component('SubscribeAd', SubscribeAd);

import HybridMedia from './HybridMedia';
Vue.component('HybridMedia', HybridMedia);

// translations
import VueI18n from 'vue-i18n';
import axios from 'axios';
Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: 'locale',
	fallbackLocale: 'locale',
	messages: {
		locale: window.langMap,
	},
});

window.addEventListener('DOMContentLoaded', function () {
	document.dispatchEvent(new CustomEvent('lichen:loaded'));

	// show reference popups
	const references = document.querySelectorAll('.ToggleUp');
	const popups = document.querySelectorAll('.ToggleUp [data-panel]');
	Array.prototype.forEach.call(references, (reference) => {
		reference.querySelector('a').addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
			Array.prototype.forEach.call(popups, (popup) => {
				popup.setAttribute('aria-hidden', true);
			});
			const popup = reference.querySelector('[data-panel]');
			const state = popup.getAttribute('aria-hidden');
			popup.setAttribute('aria-hidden', !!!state);
		});
	});
	document.addEventListener('click', (e) => {
		if (!e.target.closest('.ToggleUp')) {
			Array.prototype.forEach.call(popups, (popup) => {
				popup.setAttribute('aria-hidden', true);
			});
		}
	});

	// handle mobile account navigation toggling
	const accountNav = document.querySelector('#articleNavigationMenu');
	const accountNavToggler = document.querySelector('[aria-controls="articleNavigationMenu"]');
	let accountNavVisible = false;
	if (accountNav && accountNavToggler) {
		accountNavToggler.addEventListener('click', (e) => {
			e.preventDefault();
			if (accountNavVisible) {
				accountNav.classList.add('u-showForMedium');
				accountNavToggler.setAttribute('aria-expanded', false);
			} else {
				accountNav.classList.remove('u-showForMedium');
				accountNavToggler.setAttribute('aria-expanded', true);
			}
			accountNavVisible = !accountNavVisible;
		});
	}

	// initialize components on page load
	const taxonomyExplorer = document.getElementById('taxonomyExplorer');
	if (taxonomyExplorer) {
		new Vue({ el: taxonomyExplorer, i18n });
	}
	if (document.querySelector('[data-lichen-vue]')) {
		var els = document.querySelectorAll('[data-lichen-vue]');
		Array.prototype.forEach.call(els, function (el) {
			new Vue({ el: el, i18n });
		});
	}
	// initialize modals
	if (document.querySelector('[data-lichen-dialog]')) {
		const els = document.querySelectorAll('[data-lichen-dialog]');
		Array.prototype.forEach.call(els, function (el) {
			const id = el.getAttribute('data-lichen-dialog');
			new Vue({
				name: 'Dialog',
				el: el,
				i18n,
				data() {
					return {
						open: false,
						opener: null,
						id: id,
					};
				},
				mounted() {
					const togglers = document.querySelectorAll('[data-lichen-dialog-toggle="' + this.id + '"]');
					Array.prototype.forEach.call(togglers, (toggler) => {
						toggler.addEventListener('click', this.show);
					});
					const inlineLinks = document.querySelectorAll('[aria-controls="Lightbox-html"]');
					Array.prototype.forEach.call(inlineLinks, (toggler) => {
						toggler.addEventListener('click', this.showHtmlInDialog);
					});
					const embedMaps = document.querySelectorAll('[aria-controls="Lightbox-embed-map"]');
					Array.prototype.forEach.call(embedMaps, (toggler) => {
						toggler.addEventListener('click', this.showStatusAndTrendsMap);
					});
				},
				methods: {
					show(e) {
						e.preventDefault();
						this.open = true;
						this.opener = e.target;
						if (e.target.hasAttribute('data-lichen-reference')) {
							this.$nextTick(() => {
								this.$el.querySelector('iframe').src = e.target.getAttribute('data-lichen-reference');
								window.addEventListener(
									'message',
									(e) => {
										if (e.data === 'close') {
											this.close();
											window.location.reload();
										}
									},
									false
								);
							});
						}
						this.loadImages();
					},
					showHtmlInDialog(e) {
						if (this.id === 'appendix') {
							e.preventDefault();
							this.open = true;
							this.opener = e.target;
							const src = e.target.getAttribute('href');
							this.$nextTick(() => {
								this.$el.querySelector('iframe').src = src;
								const title = this.$el.querySelector('h1');
								if (title) {
									title.textContent = e.target.textContent;
								}
							});
						}
					},
					showStatusAndTrendsMap(e) {
						if (this.id === 'statusAndTrendsMap') {
							e.preventDefault();
							this.open = true;
							this.opener = e.target;
							this.loadImages();
						}
					},
					close() {
						this.open = false;
						// important: put focus back on element that triggered the dialog
						if (this.opener) {
							this.opener.focus();
						}
					},
					loadImages() {
						if (this.id === 'statusAndTrendsMap') {
							// need to load images
							this.$nextTick(() => {
								const images = this.$el.querySelectorAll('img');
								console.log(images);
								Array.prototype.forEach.call(images, (image) => {
									if (image.hasAttribute('data-image-lazy')) {
										if (image.hasAttribute('data-sizes')) {
											image.setAttribute('sizes', image.getAttribute('data-sizes'));
											image.removeAttribute('data-sizes');
										}
										// set srcset
										if (image.hasAttribute('data-srcset')) {
											image.setAttribute('srcset', image.getAttribute('data-srcset'));
											image.removeAttribute('data-srcset');
										}
										// set src
										image.setAttribute('src', image.getAttribute('data-src'));
										image.removeAttribute('data-src');
										// on image load event
										image.onload = function () {
											// set loaded attribute
											image.setAttribute('data-image-loaded', true);
										};
									}
								});
							});
						}
					},
				},
			});
		});
	}

	// lightbox
	if (document.querySelector('[data-lichen-lightbox]')) {
		const els = document.querySelectorAll('[data-lichen-lightbox]');
		Array.prototype.forEach.call(els, function (el) {
			const id = el.getAttribute('data-lichen-lightbox');
			new Vue({
				name: 'Lightbox',
				el: el,
				i18n,
				data() {
					return {
						open: false,
						opener: null,
						goToAsset: null,
						list: [],
					};
				},
				mounted() {
					document.addEventListener('click', (e) => {
						// ignore if inside .HybridMedia to avoid conflicting lightboxes
						const conflict = e.target.closest('.HybridMedia');
						if (conflict) {
							return;
						}
						const target = e.target.closest('[data-asset-id]');
						if (target) {
							e.preventDefault();
							this.list = [];
							this.opener = e.target;
							const assetId = target.getAttribute('data-asset-id');
							this.goToAsset = assetId;
							const wrap = e.target.closest('[data-lightbox-key]');
							if (!wrap) {
								return;
							}
							const media = wrap.querySelectorAll('[data-asset-id]');
							Array.prototype.forEach.call(media, (item) => {
								let mediaType = item.getAttribute('data-media-type');
								if (mediaType) {
									if (mediaType.indexOf('image') !== -1) {
										mediaType = 'photo';
									}
									const itemAssetId = item.getAttribute('data-asset-id');
									this.list.push({
										assetId: itemAssetId,
										title: item.getAttribute('data-asset-title'),
										caption: item.getAttribute('data-asset-caption'),
										asset: {
											assetId: itemAssetId,
											source: parseInt(itemAssetId) > 1000000 ? 'ebird' : 'ml',
											comName: item.getAttribute('data-asset-comname'),
											sciName: item.getAttribute('data-asset-sciname'),
											subnational1Name: item.getAttribute('data-asset-stateprov'),
											countryName: item.getAttribute('data-asset-country'),
											obsDtDisplay: item.getAttribute('data-asset-date'),
											userDisplayName: item.getAttribute('data-asset-username'),
											subId: item.getAttribute('data-asset-checklistid'),
											assetFormatCode: mediaType,
											citationUrl: item.getAttribute('data-asset-mllink'),
											citationName: item.getAttribute('data-asset-citationname'),
											credit: item.getAttribute('data-asset-credit'),
											restricted: item.getAttribute('data-asset-restricted') === 'true',
										},
									});
								}
							});
							this.open = true;
						}
					});
				},
				methods: {
					close() {
						this.open = false;
						// important: put focus back on element that triggered the dialog
						if (this.opener) {
							this.opener.focus();
						}
					},
				},
			});
		});
	}

	// media feed on bottom of gallery pages
	if (document.querySelector('[data-lichen-mediagallery]')) {
		const els = document.querySelectorAll('[data-lichen-mediagallery]');
		Array.prototype.forEach.call(els, function (el) {
			const url = el.getAttribute('data-mediagallery-url');
			new Vue({
				name: 'MediaGallery',
				el: el,
				i18n,
				data() {
					return {
						list: [],
					};
				},
				mounted() {
					axios
						.get(url)
						.then((res) => {
							if (res.data.results.content.length) {
								this.list = res.data.results.content;
							} else {
								this.$el.style.display = 'none';
							}
						})
						.catch((err) => {
							this.$el.style.display = 'none';
						});
				},
				methods: {},
			});
		});
	}

	// handle dismissing messages
	const messages = document.querySelectorAll('.Message');
	Array.prototype.forEach.call(messages, (message) => {
		const close = message.querySelector('.Message-action');
		if (close) {
			close.addEventListener('click', (e) => {
				e.preventDefault();
				message.setAttribute('aria-hidden', true);
			});
		}
	})
});

// handle video player in eBird Status & Trends
// this is temporary until the shared tag is updated to use Vue components
if (document.querySelector('[data-vis-video]')) {
	const vis = document.querySelector('[data-vis-video]');
	const videoDiv = vis.querySelector('[data-videoplayer]');
	const baseMediaUrl = videoDiv.getAttribute('data-videoplayer');
	const paths = baseMediaUrl.split('/');
	const assetId = paths[paths.length - 1];
	const cells = document.querySelectorAll('[data-vis-timeline-cell]');
	const weeks = Array.prototype.map.call(cells, (cell) => {
		return cell.getAttribute('data-vis-timeline-cell');
	});
	const weekTextElement = document.querySelector('[data-vis-timeline-description]');
	new Vue({
		name: 'VisVideo',
		el: vis,
		i18n,
		components: { CloVideoPlayer },
		data() {
			return {
				assetId: assetId,
				framesPerSecond: 4,
				weeks: weeks,
				activeWeek: 0,
				animatedText: '',
				cells: cells,
				weekTextElement: weekTextElement,
			};
		},
		template: `<CloVideoPlayer
			:assetId="assetId"
			map
			:frameStep="framesPerSecond"
			v-on:video-time-updated="videoTimeUpdated"
			:animatedText="animatedText"
		/>`,
		mounted() {
			this.videoTimeUpdated(0);
		},
		methods: {
			videoTimeUpdated(currentTime) {
				this.activeWeek = Math.floor(currentTime * this.framesPerSecond);
				const week = this.weeks[this.activeWeek];
				const month = week.slice(0, 2);
				const day = week.slice(2, 4);
				const dateObject = new Date(['2016', month, day].join('-'));
				this.animatedText = dateObject.toLocaleDateString(navigator.language, {
					month: 'long',
					day: 'numeric',
					timeZone: 'UTC',
				});
				this.showCurrentWeek();
			},
			showCurrentWeek() {
				this.weekTextElement.textContent = this.animatedText;
				Array.prototype.forEach.call(cells, (cell) => {
					const week = cell.getAttribute('data-vis-timeline-cell');
					if (week === this.weeks[this.activeWeek]) {
						cell.classList.add('u-color-week-current');
					} else {
						cell.classList.remove('u-color-week-current');
					}
				});
			},
		},
	});
}

// Inline media
// TODO: update html article content and ckeditor to use vue component
// so that this extra wrapping component isn't needed
const inlineMediaElements = document.querySelectorAll('[data-inlinemedia]');
Array.prototype.forEach.call(inlineMediaElements, (el) => {
	const galleryData = el.getAttribute('data-inlinemedia');
	const text = el.textContent;
	const startVisible = el.hasAttribute('data-inlinemedia-startvisible') ? true : false;
	const alignRight = el.hasAttribute('data-inlinemedia-alignright') ? true : false;
	new Vue({
		name: 'InlineMedia',
		el: el,
		i18n,
		components: { CloInlineMedia },
		data() {
			return {
				galleryData: JSON.parse(galleryData),
				text: text,
				startVisible: startVisible,
				alignRight: alignRight,
			};
		},
		template: `<CloInlineMedia
			:galleryData="galleryData"
			:startVisible="startVisible"
			:alignRight="alignRight"
			><span v-html="text"></span></CloInlineMedia>`,
	});
});
