<template>
	<CloActivityBadge :badges="badges" :title="comName" reverse />
</template>

<script>
import axios from 'axios';
import CloActivityBadge from '@/layouts/CloActivityBadge/CloActivityBadge.vue';

export default ({
	name: 'MyActivityBadges',
	components: {
		CloActivityBadge,
	},
	props: {
		userId: {
			type: String,
			required: true,
		},
		speciesCode: {
			type: String,
			required: true
		},
		comName: {
			type: String,
			required: true
		},
		mediaUrl: {
			type: String,
			required: true
		},
		obsUrl: {
			type: String,
			required: true
		},
		ebirdApiKey: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			badges: [
				{
					label: 'Seen',
					icon: 'badgeSeen',
					href: 'https://ebird.org/lifelist?spp=' + this.speciesCode + '&time=life',
					loading: true,
				},
				{
					label: 'Photo',
					icon: 'badgePhotographed',
					href: this.mediaUrl + '/catalog?userId=' + this.userId + '&taxonCode=' + this.speciesCode + '&mediaType=p',
					loading: true,
				},
				{
					label: 'Audio',
					icon: 'badgeRecordedAudio',
					href: this.mediaUrl + '/catalog?userId=' + this.userId + '&taxonCode=' + this.speciesCode + '&mediaType=a',
					loading: true,
				}
			],
		};
	},
	mounted() {
		axios
			.get(this.mediaUrl + '/api/v1/stats/obs', {
					params: {
						taxonCode: this.speciesCode,
						userId: this.userId
					},
				})
			.then((res) => {
				this.badges[1].loading = false;
				this.badges[2].loading = false;
				if (res.data.userCounts.withPhoto > 0) {
					this.badges[1].achieved = true;
					this.badges[1].text = 'You have photographed it';
				} else {
					this.badges[1].text = 'You have NOT photographed it';
				}
				if (res.data.userCounts.withAudio > 0) {
					this.badges[2].achieved = true;
					this.badges[2].text = 'You have recorded audio of it';
				} else {
					this.badges[2].text = 'You have NOT recorded audio of it';
				}
				this.$forceUpdate();
			})
			.catch((err) => {
				console.log(err);
			});
		const username = btoa(this.userId.replace('USER', ''));
		axios
			.get(this.obsUrl + this.speciesCode, {
					params: {
						username: username,
					},
					headers: { 'X-eBirdApiToken': this.ebirdApiKey },
				})
			.then((res) => {
				this.badges[0].loading = false;
				if (res.data.userObsCount > 0) {
					this.badges[0].achieved = true;
					this.badges[0].text = 'You have seen it';
				} else {
					this.badges[0].text = 'You have NOT seen it';
				}
				this.$forceUpdate();
			})
			.catch((err) => {
				console.log(err);
			});
	},
});
</script>

<style lang="scss">
.ActivityBadge--reverse {
	.ActivityBadge-badge {
		color: subdueColor($color-primary-1, true);
	}
	.ActivityBadge-badge--achieved {
		color: $color-primary-2;
	}	
	.ActivityBadge-modal {
		.ActivityBadge-badge {
			color: $color-text-muted;
		}
		.ActivityBadge-badge--achieved {
			color: $color-link;
		}
	}
}
</style>
